<template>
  <!-- 活动管理 -->
  <div>
    <div class="text">活动管理</div>
    <!-- 顶部新增按钮 -->
    <div class="onBtn">
      <el-button
        type="warning"
        size=""
        @click="addNewProfile"
        style="margin-left: auto"
        >新增</el-button
      >
    </div>

    <!-- 中间表格区 -->
    <div>
      <el-table border :data="data">
        <el-table-column label="序号" align="center">1</el-table-column>
        <el-table-column label="活动名称" align="center"></el-table-column>
        <el-table-column label="对应数字通证" align="center"></el-table-column>
        <el-table-column label="活动日期" align="center"></el-table-column>
        <el-table-column label="活动时间" align="center">
          <template>
            <el-button type="" size="mini" @click="activity = true"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="奖池奖励数量" align="center"></el-table-column>
        <el-table-column label="开奖时间" align="center"></el-table-column>
        <el-table-column label="交易买入条件" align="center"></el-table-column>
        <el-table-column label="抽奖规则" align="center"></el-table-column>
        <el-table-column label="幸运生肖个数" align="center"></el-table-column>
        <el-table-column label="H5地址" align="center"></el-table-column>
        <el-table-column label="状态" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="300">
          <template>
            <el-button type="" size="mini">查看记录</el-button>
            <el-button type="" size="mini">查看统计</el-button>
            <el-button type="danger" size="mini">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 查看活动详情弹窗 -->
    <el-dialog title="活动时间详情" :visible.sync="activity" width="50%">
      <div v-if="activityTime">
        <div v-for="(item, index) in activityTime" :key="index">
          <span style="margin: 0 20px"
            >活动开始时间: <span style="margin: 0 10px">{{ item.start }}</span>
          </span>
          <span
            >活动结束时间: <span style="margin: 0 10px">{{ item.endThe }}</span>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查看活动详情
      activity: false,
      //
      data: [{}],
      total: 23,
      currentPage4: 1,
      activityTime: [
        { start: "2019-02-11 10:00", endThe: "2019-02-11 12:00" },
        { start: "2019-02-11 10:00", endThe: "2019-02-11 12:00" },
        { start: "2019-02-11 10:00", endThe: "2019-02-11 12:00" },
      ],
    };
  },
  methods: {
    addNewProfile() {
      console.log(123);
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  padding: 10px;
  font-size: 18px;
  color: #409eff;
}
.onBtn {
  display: flex;
  align-items: center;
  padding: 40px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
